import ModalPopup from '@/shared/components/ModalPopup.vue';
import { defineComponent, ref, watch } from '@vue/composition-api';
import { PRE_SAVE_ACTION_TYPES } from '@/util/preSaveActions';
export default defineComponent({
    components: {
        ModalPopup
    },
    props: {
        actionType: {
            type: String,
            default: ''
        },
        value: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const { root } = context;
        const modalInfoText = ref('');
        watch(() => props.actionType, () => {
            if (props.actionType) {
                if (props.actionType === PRE_SAVE_ACTION_TYPES.BOOKING) {
                    modalInfoText.value = root.$i18n
                        .t('common.preSaveActionModal.info.book')
                        .toString();
                }
                else if (props.actionType === PRE_SAVE_ACTION_TYPES.COUPON_CODE) {
                    modalInfoText.value = root.$i18n
                        .t('common.preSaveActionModal.info.coupon')
                        .toString();
                }
                else if (props.actionType === PRE_SAVE_ACTION_TYPES.CHAT) {
                    modalInfoText.value = root.$i18n.t('common.preSaveActionModal.info.chat');
                }
                else if (props.actionType === PRE_SAVE_ACTION_TYPES.SPACE_ACTIVATION) {
                    modalInfoText.value = root.$i18n.t('common.preSaveActionModal.info.space_activation');
                }
            }
        }, { immediate: true });
        return {
            modalInfoText
        };
    }
});
