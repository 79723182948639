import { computed, defineComponent, onMounted } from '@vue/composition-api';
import NearbyMap from '@/Search/NearbyMap.vue';
import { SITE_URL } from '@/App.vue';
export default defineComponent({
    components: {
        NearbyMap
    },
    props: {
        venue: {
            type: Object,
            default: () => { }
        }
    },
    setup(props, context) {
        const { emit } = context;
        const structuredDataMap = computed(() => {
            return {
                '@type': 'Venue',
                name: props.venue.venue_geo.city.seo_entity_name,
                hasMap: {
                    '@type': 'Map',
                    mapType: { '@id': 'https://schema.org/VenueMap' },
                    url: `${SITE_URL}${window.location.pathname}`
                }
            };
        });
        onMounted(() => {
            emit('structured-data-ready', structuredDataMap.value);
        });
    }
});
