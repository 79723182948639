import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    props: {
        venue: {
            type: Object,
            default: () => null
        },
        isSpacePage: {
            type: Boolean,
            default: false
        }
    }
});
