import ModalPopup from '@/shared/components/ModalPopup.vue';
import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    components: { ModalPopup },
    props: {
        value: {
            type: Boolean,
            default: false
        }
    }
});
